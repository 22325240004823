import React from 'react'
import { createRoot } from 'react-dom/client'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import HomePage from './loggedOut/HomePage'
import { Footer } from './loggedOut/components/Footer'

// import { GoogleOAuthProvider } from '@react-oauth/google'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <HomePage />
        <Footer />
      </>
    ),
  },
])

const element = (
  <React.StrictMode>
    {/* <GoogleOAuthProvider clientId="755830111751-9r620gpi0f0ho7da4k401gd92p6rv9ao.apps.googleusercontent.com"> */}
    <RouterProvider router={router} />
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>
)

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(element)
